import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Authenticator, View } from "@aws-amplify/ui-react";
import config from "./aws-exports";
import { Amplify } from "aws-amplify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Amplify.configure(config);
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Authenticator.Provider>
      {/* <View> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/* </View> */}
    </Authenticator.Provider>
  </QueryClientProvider>
);
