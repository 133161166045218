import { useEffect, useState } from "react";
import fb from "../assets/fb.png";
import insta from "../assets/insta.png";
import email from "../assets/email.svg";
import phone from "../assets/phone.png";
import "../styles/Contact.css";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { generateClient } from "aws-amplify/api";
import { createCustomers } from "../graphql/mutations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Contact = () => {
  const notify = () => {
    setFormState({
      forename: "",
      surname: "",
      address: "",
      phoneNumber: "",
      message: "",
    });
    toast("Sent!");
  };
  interface EmailParams {
    forename: string | undefined;
    surname: string | undefined;
    address: string | undefined;
    phoneNumber: string | undefined;
    message: string | undefined;
  }
  useEffect(() => {
    document.title = "Contact - Footprints Podiatry";
  });
  const handleSubmit = () => {
    window.open("mailto: pod.footprints@gmail.com");
  };

  const [formState, setFormState] = useState<EmailParams>({
    forename: undefined,
    surname: undefined,
    address: undefined,
    phoneNumber: undefined,
    message: undefined,
  });

  const [errorState, setErrorState] = useState<string | undefined>(undefined);
  const client = generateClient();
  const handleEmailSubmit = async (e: any) => {
    e.preventDefault();
    const { forename, surname, address, phoneNumber, message } = formState;

    if (forename && surname && address && phoneNumber && message) {
      if (isNaN(+phoneNumber) === true) {
        setErrorState("Phone number must be a number");
      } else {
        try {
          await client.graphql({
            query: createCustomers,
            variables: {
              input: { forename, surname, address, phoneNumber, message },
            },
          });
          console.log("sent");
          notify();
          setErrorState(undefined);
        } catch (e: any) {
          setErrorState(e.errors[0].message);
          console.log(e);
        }
      }
    } else {
      setErrorState(
        "You must enter a name, valid phone number, address and message"
      );
    }
  };
  return (
    <div className="pageContainer">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <p style={{ textAlign: "center" }}>
        For all enquires, please provide your full name, address and contact
        details
      </p>
      <div className="Contact">
        <form onSubmit={(e) => handleEmailSubmit(e)} id="contactForm">
          {errorState && <h5>{errorState}</h5>}
          <h3
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0",
              color: "#6a6a6a",
            }}
          >
            Send a message!
          </h3>
          <label>Name *</label>
          <div
            style={{ display: "flex", alignItems: "center", color: "black" }}
          >
            <input
              style={{ width: "100%", marginRight: "10px" }}
              placeholder="Forename"
              type="text"
              onChange={(e) => {
                setFormState({ ...formState, forename: e?.target.value });
                setErrorState(undefined);
              }}
              value={formState.forename}
            />
            <input
              style={{ width: "100%" }}
              placeholder="Surname"
              type="text"
              onChange={(e) => {
                setFormState({ ...formState, surname: e?.target.value });
                setErrorState(undefined);
              }}
              value={formState.surname}
            />
          </div>
          <label>Phone number *</label>
          <input
            placeholder="Your phone number"
            type="text"
            onChange={(e) => {
              setFormState({
                ...formState,
                phoneNumber: e?.target.value,
              });
              setErrorState(undefined);
            }}
            value={formState.phoneNumber}
          />
          <label>Address *</label>
          <input
            placeholder="Your address"
            type="text"
            onChange={(e) => {
              setFormState({ ...formState, address: e?.target.value });
              setErrorState(undefined);
            }}
            value={formState.address}
          />
          <label>Message *</label>
          <textarea
            style={{ fontFamily: "monospace", fontSize: "medium" }}
            placeholder="Type your message here..."
            onChange={(e) => {
              setFormState({ ...formState, message: e?.target.value });
              setErrorState(undefined);
            }}
            value={formState.message}
          />
          <input type="submit" value="Send" id="submitButton" />
        </form>

        <div className="links">
          <h3
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#6a6a6a",
            }}
          >
            Contact Details
          </h3>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              id="phone"
              alt="phone"
              src={phone}
              style={{
                paddingLeft: "10px",
                paddingRight: "20px",
                height: "30px",
              }}
            ></img>
            <p>
              <a href="tel:07784494265" id="contactLink">
                07784494265
              </a>
            </p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              id="email"
              alt="email"
              src={email}
              style={{
                paddingLeft: "10px",
                paddingRight: "20px",
                height: "30px",
              }}
              onClick={() => handleSubmit()}
            ></img>
            <p>
              <a onClick={() => handleSubmit()} id="contactLink">
                pod.footprints@gmail.com
              </a>
            </p>
          </div>
          {/* <div style={{ display: "flex", alignItems: "center" }}>
            <img
              id="insta"
              alt="instagram-logo"
              src={insta}
              style={{
                paddingLeft: "10px",
                paddingRight: "20px",
                height: "30px",
              }}
            ></img>
            <p>
              <a id="contactLink">@footprintspod</a>
            </p>
          </div> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              id="fb"
              alt="facebook-logo"
              src={fb}
              style={{
                paddingLeft: "10px",
                paddingRight: "20px",
                height: "30px",
              }}
            ></img>
            <p>
              <a
                id="contactLink"
                href={
                  "https://www.facebook.com/profile.php?id=61567610917163&mibextid=LQQJ4d"
                }
              >
                Footprints Podiatry
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
