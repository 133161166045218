import { useEffect } from "react";
import "../styles/Treatments.css";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

export const Treatments = () => {
  useEffect(() => {
    document.title = "Treatments - Footprints Podiatry";
  });
  return (
    <div className="pageContainer">
      <Header />
      <div className="Treatments">
        <div id="treatmentsText">
          <h2>
            Home Visit Service - £46{" "}
            <span style={{ fontWeight: "400", fontStyle: "italic" }}>
              (cash or card accepted)
            </span>
          </h2>
          <h3>Currenly offering visits in the Innerleithen area</h3>
          <ul
            style={{ color: "black", fontFamily: `"Kumbh Sans", sans-serif` }}
          >
            <li>
              <h4>Toenail cutting and reduction of thick toenails</h4>
            </li>
            <ul>
              <li>
                There are many different nail conditions and this can make it
                difficult to manage yourself. I can diagnose nail conditions and
                treat them in the most effective manner, advise any
                interventions required and keep you comfortable.
              </li>
              <li>
                Many people suffer from thick toenails - this can cause pressure
                and grow to be quite uncomfortable and very difficult to cut.
              </li>
              <li>
                This can be caused by trauma (damage to the nail), skin
                conditions such as psoriasis or poor circulation among many
                others. I can reduce such nails using my handy electric file!
              </li>
            </ul>
            <li>
              <h4>Callus and corn removal</h4>
            </li>
            <ul>
              <li>
                Hard skin or callus is caused by pressure. It can build up, for
                some more than others, to a level where it is uncomfortable. By
                removing this using a scalpel I can keep you feeling light on
                your feet.
              </li>
              <li>
                Corns are caused by an area of high pressure or friction. It is
                simply a thickened layer of skin but can be pesky! People
                usually report a feeling of a stone in their shoes. I can remove
                your corn and work with you to prevent them coming back where
                possible. Generally I advise against corn plasters as they can
                have a damaging effect on the skin, potentially leading to break
                down in the skin and infection.
              </li>
            </ul>
            <li>
              <h4>Ingrown toenails</h4>
            </li>
            <ul>
              <li>
                An ingrown toenail is when a nail grows in such a manner that it
                can pierce through the skin. This causes acute pain and can lead
                to infection.
              </li>
              <li>
                By managing such toenails in the correct way, I can prevent this
                and advise about alternative management; such as nail surgery.
              </li>
            </ul>
            <li>
              <h4>Verruca diagnosis and advice</h4>
            </li>
            <ul>
              <li>
                A verucca is a common wart caused by the Human Papilloma Virus
                (HPV), usually found on the sole of the foot or toes. They can
                become painful and require treatment, or may resolve without
                intervention.
              </li>
              <li>
                Through advice and diagnosis, I can help keep you comfortable
                and limit any spreading of the virus.
              </li>
            </ul>
            <li>
              <h4>Diabetes care and management</h4>
            </li>
            <ul>
              <li>
                Diabetes is a metabolic condition which is becoming more
                prevalent in Scotland. It can affect many parts of the body,
                feet being one of them. This can cause a change in circulation,
                healing, nerve sensation and skin and nail appearance.
              </li>
              <li>
                It is recommended that people with diabetes have an annual
                assessment of their feet to prevent and document any changes.
              </li>
            </ul>
            <li>
              <h4>Wound prevention</h4>
            </li>
            <ul>
              <li>
                An ulcer is where the layers of the skin break down creating an
                open wound which is difficult to heal. There are many different
                causes of ulcers on the feet such as; nerve damage; poor
                circulation and foot deformities.
              </li>
              <li>
                Podiatrists work to prevent ulcers/wounds as they are a very
                limiting and serious, with the most extreme outcome being
                amputation, and prevention is always more effective.
              </li>
            </ul>
            <li>
              <h4>Foot care advice and tips</h4>
            </li>
            <ul>
              <li>
                This can range from how to cut toenails, managing foot hygiene,
                recommending creams and what footwear I recommend for an
                individual, to diagnosing conditions and referring on to other
                health professionals where required.
              </li>
            </ul>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
