import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import "./styles/Home.css";
import { Contact } from "./pages/Contact";
import { Treatments } from "./pages/Treatments";

const App = () => {
  return <AppRoutes />;
};

export const AppRoutes = () => {
  return (
    <>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/treatments" element={<Treatments />} />
        </Routes>
      </main>
    </>
  );
};

export default App;
