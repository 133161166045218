import menu from "../assets/menu.png";
import logo from "../assets/logo.png";
import logolandscape from "../assets/logo-landscape.png";
import cancel from "../assets/cancel.png";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuClicked, setMenuClicked] = useState(false);
  useEffect(() => {
    document.title = "Home - Footprints Podiatry";
  });

  const handleMenuClick = () => {
    setMenuClicked(!menuClicked);
  };

  return (
    <>
      <header className="App-header">
        {!menuClicked && (
          <>
            <img
              className={location.pathname === "/" ? "logoAtHome" : "logo"}
              src={logo}
              alt="header logo"
              onClick={() => {
                location.pathname !== "/" && navigate("/");
                location.pathname !== "/" && console.log("clicked");
              }}
            ></img>
            <img
              className="logolandscape"
              alt="header logo"
              src={logolandscape}
              onClick={() => {
                navigate("/");
              }}
            ></img>
          </>
        )}
        <div className="phoneMenu">
          {!menuClicked && (
            <img
              className="menuOpenIcon"
              alt="menu-open"
              src={menu}
              onClick={() => handleMenuClick()}
            ></img>
          )}
          {menuClicked && (
            <>
              <h1
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              ></h1>
              <Modal
                ariaHideApp={false}
                contentLabel="Selected Option"
                isOpen={menuClicked}
                style={{
                  content: {
                    backgroundColor: "#7477c1",
                    border: "20px",
                    inset: "0px",
                  },
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0)",
                  },
                }}
              >
                <div className="modal">
                  <img
                    src={cancel}
                    className="modalExit"
                    alt="menu-exit"
                    onClick={() => handleMenuClick()}
                  ></img>
                  <a
                    id="modalLink"
                    style={{ padding: "10px 10px 10px 10px" }}
                    onClick={() => {
                      setMenuClicked(false);
                      navigate("/");
                    }}
                  >
                    Home
                  </a>

                  <a
                    id="modalLink"
                    style={{ padding: "10px 10px 10px 10px" }}
                    onClick={() => {
                      setMenuClicked(false);
                      navigate("/treatments");
                    }}
                  >
                    Treatments
                  </a>
                  <a
                    id="modalLink"
                    style={{ padding: "10px 10px 10px 10px" }}
                    onClick={() => {
                      setMenuClicked(false);
                      navigate("/contact");
                    }}
                  >
                    Contact
                  </a>
                </div>
              </Modal>
            </>
          )}
        </div>
        <div className="fullScreenMenu" style={{ paddingBottom: "30px" }}>
          <a
            id="headerLink"
            style={{ padding: "10px 10px 10px 10px" }}
            onClick={() => navigate("/")}
          >
            Home
          </a>

          <a
            id="headerLink"
            style={{ padding: "10px 10px 10px 10px" }}
            onClick={() => {
              navigate("/treatments");
            }}
          >
            Treatments
          </a>
          <a
            id="headerLink"
            style={{ padding: "10px 10px 10px 10px" }}
            onClick={() => navigate("/contact")}
          >
            Contact
          </a>
        </div>
      </header>
    </>
  );
};
