import hcpc from "../assets/hcpc.png";
import royal from "../assets/royal-college.png";
import "./styles/footer.css";

export const Footer = () => {
  return (
    <>
      <footer className="pageFooter">
        <div className="footerImages">
          <img src={hcpc} alt="HCPC Certified Logo"></img>
        </div>
        <div className="footerText">
          <p>
            Registered with the Health and Care Professions Council and insured
            by the Royal College of Podiatry
          </p>
          {/* <p>
            <span>© Footprints Podiatry 2024</span>&nbsp;| &nbsp;
            <a id="link" href="tel:07784494265">
              07784494265
            </a>
            &nbsp;| &nbsp;
            <a id="link" href="mailto:pod.footprints@gmail.com">
              pod.footprints@gmail.com
            </a>
          </p> */}

          <p>© Footprints Podiatry 2024</p>
          <p>
            <a id="link" href="tel:07784494265">
              07784494265
            </a>
            &nbsp;| &nbsp;
            <a id="link" href="mailto:pod.footprints@gmail.com">
              pod.footprints@gmail.com
            </a>
          </p>
        </div>
        <div className="footerImages">
          <img
            src={royal}
            alt="Royal College of Podiatrists Registered Logo"
          ></img>
        </div>
      </footer>
    </>
  );
};
